import { Action, action } from 'easy-peasy'
import { ISortSettings } from '../hooks/usePagedTableExtended'

export interface ISearchFilterState {
  search?: string

  setCurrent: Action<ISearchFilterState, string>
  removeCurrent: Action<ISearchFilterState>
}
export interface IColumnFilterState {
  column?: any[]

  setCurrent: Action<IColumnFilterState, any[]>
  removeCurrent: Action<IColumnFilterState>
}
export interface IColumnSortState {
  state?: ISortSettings

  setCurrent: Action<IColumnSortState, Record<string, any>>
  removeCurrent: Action<IColumnSortState>
}
export interface IUnassignedFilterState {
  isUnassigned?: boolean

  setCurrent: Action<IUnassignedFilterState, boolean>
  removeCurrent: Action<IUnassignedFilterState>
}
export interface IEvaluatorFilterState {
  evaluatorId?: string

  setCurrent: Action<IEvaluatorFilterState, string>
  removeCurrent: Action<IEvaluatorFilterState>
}
export interface IPaymentStatusFilterState {
  paymentState?: string

  setCurrent: Action<IPaymentStatusFilterState, string>
  removeCurrent: Action<IPaymentStatusFilterState>
}
export interface IApplicationStatusFilterState {
  applicationState?: string

  setCurrent: Action<IApplicationStatusFilterState, string>
  removeCurrent: Action<IApplicationStatusFilterState>
}

export interface IApplicationProgressStatusFilterState {
  applicationProgressState?: string

  setCurrent: Action<IApplicationProgressStatusFilterState, string>
  removeCurrent: Action<IApplicationProgressStatusFilterState>
}
export interface IApplicationIdStoreState {
  applicationStoreId?: string
  setCurrent: Action<IApplicationIdStoreState, string>
  removeCurrent: Action<IApplicationIdStoreState>
}
export interface IApplicationResponsiveStoreState {
  applicationResponsiveState?: boolean
  setCurrent: Action<IApplicationResponsiveStoreState, boolean>
}
export const applicationSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}

export const applicationRefSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const applicationNameSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}

export const applicationNotesSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}

export const additionalServiceSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const userManagementSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const schoolSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const fraudulentSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const reportIssueSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const dashboardMonthFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const unassignedFilterState: IUnassignedFilterState = {
  isUnassigned: false,
  setCurrent: action((state, payload) => {
    state.isUnassigned = payload
  }),
  removeCurrent: action((state) => {
    state.isUnassigned = false
  }),
}

export const selectedEvaluatorFilterState: IEvaluatorFilterState = {
  evaluatorId: '',
  setCurrent: action((state, payload) => {
    state.evaluatorId = payload
  }),
  removeCurrent: action((state) => {
    state.evaluatorId = ''
  }),
}
export const selectedPaymentStatusFilterState: IPaymentStatusFilterState = {
  paymentState: '',
  setCurrent: action((state, payload) => {
    state.paymentState = payload
  }),
  removeCurrent: action((state) => {
    state.paymentState = null
  }),
}
export const selectedApplicationStatusFilterState: IApplicationStatusFilterState = {
  applicationState: '',
  setCurrent: action((state, payload) => {
    state.applicationState = payload
  }),
  removeCurrent: action((state) => {
    state.applicationState = null
  }),
}

export const selectedApplicationProgressStatusFilterState: IApplicationProgressStatusFilterState = {
  applicationProgressState: '',
  setCurrent: action((state, payload) => {
    state.applicationProgressState = payload
  }),
  removeCurrent: action((state) => {
    state.applicationProgressState = null
  }),
}

export const applicationIdStoreState: IApplicationIdStoreState = {
  applicationStoreId: '',
  setCurrent: action((state, payload) => {
    state.applicationStoreId = payload
  }),
  removeCurrent: action((state) => {
    state.applicationStoreId = ''
  }),
}
export const selectedCountryFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const columnFilterState: IColumnFilterState = {
  column: [],
  setCurrent: action((state, payload) => {
    state.column = payload
  }),
  removeCurrent: action((state) => {
    state.column = []
  }),
}
export const dashboardSortState: IColumnSortState = {
  state: {},
  setCurrent: action((state, payload) => {
    state.state = payload
  }),
  removeCurrent: action((state) => {
    state.state = {}
  }),
}
export const preEvaluationSortState: IColumnSortState = {
  state: {},
  setCurrent: action((state, payload) => {
    state.state = payload
  }),
  removeCurrent: action((state) => {
    state.state = {}
  }),
}
export const userManagementSortState: IColumnSortState = {
  state: {},
  setCurrent: action((state, payload) => {
    state.state = payload
  }),
  removeCurrent: action((state) => {
    state.state = {}
  }),
}
export const schoolSortState: IColumnSortState = {
  state: {},
  setCurrent: action((state, payload) => {
    state.state = payload
  }),
  removeCurrent: action((state) => {
    state.state = {}
  }),
}
export const fraudulentManagementSortState: IColumnSortState = {
  state: {},
  setCurrent: action((state, payload) => {
    state.state = payload
  }),
  removeCurrent: action((state) => {
    state.state = {}
  }),
}

export const draftApplicationSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}

export const draftApplicationFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}

export const agentSelectFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}

export const draftApplicationSortState: IColumnSortState = {
  state: {},
  setCurrent: action((state, payload) => {
    state.state = payload
  }),
  removeCurrent: action((state) => {
    state.state = {}
  }),
}

export const applicationPayementFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const applicationEvaluationFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const additionalPayementFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const additionalEvaluationFilterState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const applicaitonEvaluationSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const additionalEvaluationSearchState: ISearchFilterState = {
  search: '',
  setCurrent: action((state, payload) => {
    state.search = payload
  }),
  removeCurrent: action((state) => {
    state.search = ''
  }),
}
export const applicationResponsiveStoreState: IApplicationResponsiveStoreState = {
  applicationResponsiveState: false,
  setCurrent: action((state, payload) => {
    state.applicationResponsiveState = payload
  }),
  
}
